<template>
  <div v-if="categoryDescription">
    <Heading tag="h1" size="sm" class="mb-3xs font-semibold" data-test-id="plpCategoryName">{{
      bigCommerceCategory?.name
    }}</Heading>
    <Heading
      tag="p"
      size="xxs"
      class="text-grey-darker mb-xs"
      data-test-id="plpCategoryProducts"
      data-fs="plpCategoryProducts"
    >
      {{ categoryBCData?.total }} product{{ categoryBCData?.total > 1 ? "s" : "" }}
    </Heading>
    <Text
      tag="p"
      size="sm"
      weight="semi"
      :class="['text-with-information__description', showMore ? 'hidden-description' : 'show']"
      data-test-id="plpCategoryDescription"
      data-fs="plpCategoryDescription"
    >
      {{ categoryDescription?.desc }}
    </Text>

    <Button size="sm" variant="ghost" @click="showMore = !showMore" class="!w-full mb-sm" data-test-id="plpShowBtn">
      <template #before>
        <Icon :name="showMore ? 'plus-thin' : 'minus-thin'" :size="14" />
      </template>

      {{ showMore ? "Show more" : "Show less" }}
    </Button>
  </div>
</template>

<script lang="ts" setup>
const { data: categoryBCData, bigCommerceCategory, categoryDescription } = useCategory();

const descriptionLength = computed(() => categoryDescription?.value?.desc?.length > 220);
const showMore = ref<boolean>(descriptionLength.value);
</script>

<style>
.text-with-information__description {
  position: relative;
  max-height: 135px;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  padding-bottom: 20px;
}

.hidden-description:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(248, 248, 248, 1) 80%);
  width: 100%;
  height: 5em;
}

.text-with-information__description.show {
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}

.text-with-information__description.show::before {
  display: none;
}

.text-with-information__description::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: -20px;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 20px;
  background: #f8f8f8;
  filter: blur(12px);
}
</style>
